import { isInNode, IsInWebworker } from '../EnvChecks';
import { getStaticExecutorFromRegistry } from './JobExecutor';
import type { WorkerEventsHandler } from './WorkerWrapper';
import { WorkerClassPassTransformer } from './WorkerClassPassRegistry';
import type { WorkerMsg, WorkerMsgEvent, WorkerMsgType, WorkerTaskDescription, WorkerTaskResult } from './WorkerMsg';
import { executeGenerator, isGenerator } from './WorkerPoolImpl';

export async function startWorker() {
    if(IsInWebworker()) {
        const EventHandler: WorkerEventsHandler = {
            onMessage: (cb: (msg: WorkerMsg | WorkerMsg[]) => void) => {
                self.onmessage = (e) => {
                    cb(e.data);
                }
            },
            onMessageError: (cb: (msg: WorkerMsgEvent<WorkerMsg | WorkerMsg[]> | Error) => void) => {
                self.onmessageerror = cb;
            },
            postMessage: (msg: WorkerMsg | WorkerMsg[], options?: { transfer: ArrayBufferLike[] }) => {
                self.postMessage(msg, { transfer: options?.transfer });
            }
        };
        _startWorker(EventHandler);
    } else if(isInNode()) {
        const { parentPort, isMainThread } = await import('worker_threads');
        if (isMainThread) {
            console.error('startWorker should only be executed inside worker');
            return;
        }
        const EventHandler: WorkerEventsHandler = {
            onMessage: (cb: (msg: WorkerMsg | WorkerMsg[]) => void) => {
                parentPort?.on("message", cb);
            },
            onMessageError: (cb: (msg: WorkerMsgEvent<WorkerMsg | WorkerMsg[]> | Error) => void) => {
                parentPort?.on("messageerror", cb);
            },
            postMessage: (msg: WorkerMsg | WorkerMsg[], options?: { transfer: ArrayBufferLike[] }) => {
                parentPort?.postMessage(msg, options?.transfer);
            }
        };
        _startWorker(EventHandler);
    } else { 
        console.error('startWorker should only be executed inside worker');
    }
}

function _startWorker(eventHandler: WorkerEventsHandler) {
    const initializedMsg: WorkerMsg = {
        msgGuid: 'worker-started',
        ty: 'worker-started',
        msgContent: {
        },
    };
    eventHandler.postMessage(initializedMsg);

    function executeWork<TArgs>(executorName: string, args: TArgs): any {

        let executor = getStaticExecutorFromRegistry(executorName);
        if (executor == undefined) {
            return Promise.reject(`${executorName} is not registered`);
        }
        const res = executor.execute(args);
        if (isGenerator(res)) {
            const value = executeGenerator(res);
            return value;
        }
        return res;
    }

    function handleMessageEvent(msg: WorkerMsg, classesTransformer: WorkerClassPassTransformer): WorkerMsg {
        const startT = performance.now();
        let guid: string | undefined = undefined;
        let result: any = undefined;
        let error: any = undefined;
        try {
            guid = msg.msgGuid;
            switch (msg.ty as WorkerMsgType) {
                case 'task': {
                    const td = msg.msgContent as WorkerTaskDescription;
                    const argsWithClasses = typeof td.args == 'object' ?
                        classesTransformer.transformWrappersToClasses(td.args)
                        : td.args;
                    result = executeWork(td.executorIdent, argsWithClasses);
                }; break;
                // case 'cancel-task': {
                //     case ct = msg.msgContent as WorkerTaskCancel;
                //     // result = await
                // }
                default: { error = `unexpected msg type: ${msg.ty}`}; break;
            }
        } catch (e) {
            error = e instanceof Error ? e.message : e;
            console.error(e); // make it show in up in the console;
        } finally {
            if (typeof result === 'object') {
                result = classesTransformer.transformClassesToWrappers(result);
            };
            let taskResult: WorkerTaskResult = { result, error, durationMs: performance.now() - startT };
            let response: WorkerMsg = {
                msgGuid: guid || 'unknown',
                ty: 'result',
                msgContent: taskResult
            }
            return response;
        }
    }


    eventHandler.onMessageError((errorEvent: any) => { console.error('in worker error', errorEvent) });
    eventHandler.onMessage((msg: WorkerMsg | WorkerMsg[]) => {
        let messagesHandlingStart = performance.now();
        
        const msgEventArray = Array.isArray(msg) ? msg : [msg];
        const responsesToSendBack: WorkerMsg[] = [];

        const classesTransformer = new WorkerClassPassTransformer();

        while (msgEventArray.length > 0) {
            const msgEvent = msgEventArray.shift()!;

            const response = handleMessageEvent(msgEvent, classesTransformer);
            
            responsesToSendBack.push(response);

            if (performance.now() - messagesHandlingStart > 4) {
                messagesHandlingStart = performance.now();
                const transfer: ArrayBuffer[] = [];// classesTransformer.consumeTransferablesToSendFromWorkerWithWrappers();
                classesTransformer.clearClassesToWrappersCache();
                eventHandler.postMessage(responsesToSendBack.splice(0), {transfer});
            }
        }

        if (responsesToSendBack.length > 0) {
            const transfer: ArrayBuffer[] = classesTransformer.consumeTransferablesToSendFromWorkerWithWrappers();
            eventHandler.postMessage(responsesToSendBack.splice(0), {transfer});
        }
    });
}
